import { Component, ElementRef, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlogPost, Blog } from 'src/@omnial/_models/catalog/blog.model';
import { BlogService } from 'src/@omnial/_services/catalog/blog.service';
import { SevenSpikesService } from 'src/@omnial/_services/catalog/seven.spikes.service';
import { AppSettings } from 'src/app/app.settings';
import { SwiperOptions } from 'swiper/types';

@Component({
    selector: 'app-blog-carousel',
    templateUrl: './../../../app/shared/blogs-carousel/blogs-carousel.component.html',
    styleUrls: ['./../../../app/shared/blogs-carousel/blogs-carousel.component.scss'],
    standalone: false
})
export class BlogsCarouselComponent implements OnDestroy {
  @Input() category: string;
  @Input() blogCategoryId: number;
  @Input() posts: BlogPost[];
  @ViewChildren('swiper') swiper: QueryList<ElementRef>;
  @Input() swiperPosts: BlogPost[];
  public config: SwiperOptions = null;
  public blog: Blog;
  private subscriptions: Subscription[] = []

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public sevenSpikesService: SevenSpikesService,
    public settings: AppSettings,
    public blogService: BlogService) {
    this.subscriptions.push(this.blogService.blog.subscribe(
      res => {
        this.blog = res as Blog;
        if (this.blog) {
          setTimeout(() => { this.load(); });
        }
      }));
  }

  ngOnInit(): void {
    if (this.blogCategoryId && !this.posts && !this.category) {
      this.subscriptions.push(this.blogService.blog.subscribe(
        res => {
          if (res) {
            this.blog = res as Blog;
            this.blog.categories.forEach((category) => {
              if (category.id === this.blogCategoryId) {
                this.category = category.seName;
                this.posts = category.posts;
                setTimeout(() => { this.load(); });
              }
            });
          } else {
            this.blogService.load();
          }
        }));
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  load(): void {
    // If nothing is passed as the category or posts display everything
    if (!this.category && !this.posts) {
      this.blog?.categories?.forEach((blogCat) => {
        this.posts?.concat(blogCat.posts);
      });
    } else if (this.category) {
      var blogCategory = this.blog?.categories?.find(c => c.seName == this.category);
      if (blogCategory && blogCategory.posts) {
        this.posts = blogCategory.posts;
      }
    }
    if (this.posts.length < 10) {
      let numDuplicates = 10;
      if (this.posts.length > 5) {
        numDuplicates = 2;
      } else if (this.posts.length > 3) {
        numDuplicates = 3;
      } else if (this.posts.length > 1) {
        numDuplicates = 5;
      }
      const dupPosts: BlogPost[] = [];
      for (var i = 0; i < numDuplicates; i++) {
        this.posts.forEach((post) => {
          dupPosts.push(post);
        });
      }
      this.swiperPosts = dupPosts;
    } else {
      this.swiperPosts = this.posts;
    }
    this.config = {
      resizeObserver: true,
      slidesPerView: 2,
      spaceBetween: 16,
      keyboard: true,
      grabCursor: true,
      autoplay: true,
      speed: 1000,
      loop: true,
      effect: 'slide',
      breakpoints: {
        480: { slidesPerView: 2 },
        740: { slidesPerView: 3 },
        960: { slidesPerView: 4 },
        1280: { slidesPerView: 5 },
        1700: { slidesPerView: 6 }
      }
    };
    this.swiper?.forEach((swiper) => {
      Object.assign(swiper.nativeElement, this.config);
      swiper.nativeElement.initialize();
    });
  }

  slideNext() {
    this.swiper.forEach((swiper) => {
      swiper.nativeElement?.swiper?.slideNext(500);
    });
  }

  slidePrev() {
    this.swiper.forEach((swiper) => {
      swiper.nativeElement?.swiper?.slidePrev(500);
    });
  }
}
